import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container, Typography } from "@mui/material"

export const Section = styled(SectionWrapper)`
  padding: 0;
`
export const Wrapper = styled(Container)``

export const DescriptionTags = styled.div`
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 750px;
  padding-bottom: 0;
  ${({ theme }) => theme.breakpoints.down("md")} {
    max-width: 450px;
  }
  ${({ theme }) => theme.breakpoints.down("sm")} {
    max-width: 300px;
  }
`
export const Description = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: normal;
  margin-top: 0;
  font-size: ${({ theme }) => theme.typography.pxToRem(30)};
  font-family: ${({ theme }) => theme.fonts.secondary};
  line-height: 1.33em;
  ${({ theme }) => theme.breakpoints.down("md")} {
    font-size: ${({ theme }) => theme.typography.pxToRem(24)};
    margin-bottom: 1rem;
  }
`

