import React from "react"
import ReactCompareImage from "react-compare-image"

import * as S from "./before-after-slider.styles"

const BeforeAfterSlider = ({ beforeImage, afterImage }) => {
  return (
    <S.Section>
      <S.Wrapper>
        <S.DescriptionTags>
          <S.Description component="h2">Antes</S.Description>
          <S.Description component="h2">Después</S.Description>
        </S.DescriptionTags>
        <ReactCompareImage
          leftImage={beforeImage.sourceUrl}
          rightImage={afterImage.sourceUrl}
          sliderLineColor="#46B6FC"
        />
      </S.Wrapper>
    </S.Section>
  )
}

export default BeforeAfterSlider
