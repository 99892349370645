import React from "react"
import * as S from "./hero-banner.styles.jsx"
import { Grid } from "@mui/material"

const HeroBanner = ({ companyName, title, services, date, link }) => {
  return (
    <S.Section>
      <S.Wrapper maxWidth="xl">
        {companyName && <S.Description>{companyName}</S.Description>}
        <Grid container spacing={1} alignItems="center">
          <Grid item sm={12} md={8}>
            {title && <S.Title>{title}</S.Title>}
            {services && services.length > 0 && (
              <S.Services>
                {services?.map(({ name }, index) => (
                  <li key={`service-${index}`}>{name}</li>
                ))}
              </S.Services>
            )}
          </Grid>
          <Grid item sm={12} md>
            {link && link.url && (
              <S.LinkContainer>
                <S.Description className="link">
                  Sitio web:
                  <br />
                  <S.Link href={link.url} target="_blank">
                    {link.title}
                  </S.Link>
                </S.Description>
              </S.LinkContainer>
            )}
          </Grid>
        </Grid>
      </S.Wrapper>
    </S.Section>
  )
}
export default HeroBanner
