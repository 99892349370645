import React from "react"
import Layout from "../components/layout"
import MiscContent from "../layouts/MiscContent"
import { LayoutTitle } from "../styles/app.styles"
import { useStaticQuery, graphql } from "gatsby"
import HelpTransformCompanies from "../layouts/HelpTransformCompanies"
import Services from "../layouts/Services"
import Technologies from "../layouts/Technologies"
import PeopleSaying from "../layouts/PeopleSaying"
import AboutHero from "../layouts/AboutHero"
import TeamLeaders from "../layouts/TeamLeaders"
import ServiceDescription from "../layouts/ServiceDescription"
import WorkCards from "../layouts/WorkCards/work-cards.component"
import HeroBanner from "../layouts/HeroBanner"
import CareersDescription from "../layouts/CareersDescription"
import Careers from "../layouts/Careers"
import BeforeAfterSlider from "../layouts/BeforeAfterSlider"

const Layouts = () => {
  const staticQuery = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "test.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)

  return (
    <Layout
      seo={{
        title: "Layouts - ONLY FOR DEV",
        metaRobotsNoindex: "noindex",
        metaRobotsNofollow: "nofollow",
      }}
    >
      <LayoutTitle>MiscContent</LayoutTitle>
      <MiscContent content={`<p>Some content Here</p>`} />

      <LayoutTitle>HelpTransformCompanies</LayoutTitle>
      {/*<HelpTransformCompanies*/}
      {/*  title="We build and design digital products that help transform companies."*/}
      {/*  companies={[*/}
      {/*    {*/}
      {/*      companyName: "Midnight Express",*/}
      {/*      technologyDescription: "UX Design, UI Design, Development",*/}
      {/*      image: staticQuery.img,*/}
      {/*      slug: "test",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      companyName: "Midnight Express",*/}
      {/*      technologyDescription: "UX Design, UI Design, Development",*/}
      {/*      image: staticQuery.img,*/}
      {/*      slug: "test",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      companyName: "Midnight Express",*/}
      {/*      technologyDescription: "UX Design, UI Design, Development",*/}
      {/*      image: staticQuery.img,*/}
      {/*      slug: "test",*/}
      {/*    },*/}
      {/*    {*/}
      {/*      companyName: "Midnight Express",*/}
      {/*      technologyDescription: "UX Design, UI Design, Development",*/}
      {/*      image: staticQuery.img,*/}
      {/*      caseStudy: { slug: "test" },*/}
      {/*    },*/}
      {/*  ]}*/}
      {/*  link={{ url: "./", title: "View More" }}*/}
      {/*/>*/}

      <LayoutTitle>Services</LayoutTitle>
      <Services
        title="Our Services"
        link={{ url: "./", title: "Learn More" }}
        services={[
          {
            image: staticQuery.img,
            serviceName: "Strategy",
            description:
              'Darn, we really should have not tried to take a strategic approach with that project," said no one ever. Under every great project is an entire iceberg of strategic consideration.',
          },
          {
            image: staticQuery.img,
            serviceName: "Strategy",
            description:
              'Darn, we really should have not tried to take a strategic approach with that project," said no one ever. Under every great project is an entire iceberg of strategic consideration.',
          },
          {
            image: staticQuery.img,
            serviceName: "Strategy",
            description:
              'Darn, we really should have not tried to take a strategic approach with that project," said no one ever. Under every great project is an entire iceberg of strategic consideration.',
          },
          {
            image: staticQuery.img,
            serviceName: "Strategy",
            description:
              'Darn, we really should have not tried to take a strategic approach with that project," said no one ever. Under every great project is an entire iceberg of strategic consideration.',
          },
        ]}
      />

      <LayoutTitle>Technologies</LayoutTitle>
      <Technologies
        title="Technologies We Use"
        technologies={[
          {
            technologyName: "Headless CMS",
            description: "Data modeling, Client & Server, Embedded Systems",
            brands: [
              { image: staticQuery.img },
              { image: staticQuery.img },
              { image: staticQuery.img },
            ],
          },
          {
            technologyName: "Headless CMS",
            description: "Data modeling, Client & Server, Embedded Systems",
            brands: [
              { image: staticQuery.img },
              { image: staticQuery.img },
              { image: staticQuery.img },
            ],
          },
          {
            technologyName: "Headless CMS",
            description: "Data modeling, Client & Server, Embedded Systems",
            brands: [
              { image: staticQuery.img },
              { image: staticQuery.img },
              { image: staticQuery.img },
            ],
          },
        ]}
      />

      <LayoutTitle>PeopleSaying</LayoutTitle>
      <PeopleSaying
        title="What Everyone is Saying…"
        peopleExperience="For years, we’ve laid the groundwork for a world-class team.
        Each day, we get better at building with exceptional speed, robust quality,
        and controlled costs—and we’re just getting started."
        authorImage={staticQuery.img}
        authorName="Valentino Pierra"
        businessPosition="CEO - Nutre"
      />

      <LayoutTitle>AboutHero</LayoutTitle>
      <AboutHero
        title="It’s not just about the initial impact.<br>It’s about what comes next. "
        description="We're a modern, design + digital agency, with a diverse network of creatives aligned
        to solve design and digital problems. We specialize in building web and mobile development solutions
        for the most complex challenges."
        statistics={[
          { title: "Est. 2013", description: "In Boston, MA" },
          { title: "Est. 2013", description: "In Boston, MA" },
          { title: "Est. 2013", description: "In Boston, MA" },
          { title: "Est. 2013", description: "In Boston, MA" },
        ]}
        valuesDescription="At the heart of our business are our values – <strong>Integrity, Proactiveness, Respectfulness, Responsibility.</strong>"
      />

      <LayoutTitle>TeamLeaders</LayoutTitle>
      <TeamLeaders
        title="Great teams have strong leaders.<br><strong>Meet ours.</strong>"
        teamCards={[
          {
            image: staticQuery.img,
            name: "Mark J. Mason",
            jobPosition: "CEO + Product Lead",
            biography:
              "Joe strives to improve the technology that people use in their daily lives " +
              "by making it so reliable and intuitive that it doesn’t feel like “technology” anymore. Before MojoTech, Joe spearheaded the development for RI.gov for more than a decade and was a part of many complex integration and automation projects. Prior to that, he spent close to half a decade at the Providence Journal. Most of Joe’s family are educators, so he can’t escape the desire to teach and help others. He is the organizer for the Rhode Island Ruby User Group and has served as an instructor for RampUp." +
              " Away from the computer, he is an adept guitar player and loves cooking for family and friends. Seriously, try his guacamole.",
          },
          {
            image: staticQuery.img,
            name: "Mark J. Mason",
            jobPosition: "CEO + Product Lead",
            biography:
              "Joe strives to improve the technology that people use in their daily lives " +
              "by making it so reliable and intuitive that it doesn’t feel like “technology” anymore. Before MojoTech, Joe spearheaded the development for RI.gov for more than a decade and was a part of many complex integration and automation projects. Prior to that, he spent close to half a decade at the Providence Journal. Most of Joe’s family are educators, so he can’t escape the desire to teach and help others. He is the organizer for the Rhode Island Ruby User Group and has served as an instructor for RampUp." +
              " Away from the computer, he is an adept guitar player and loves cooking for family and friends. Seriously, try his guacamole.",
          },
          {
            image: staticQuery.img,
            name: "Mark J. Mason",
            jobPosition: "CEO + Product Lead",
            biography:
              "Joe strives to improve the technology that people use in their daily lives " +
              "by making it so reliable and intuitive that it doesn’t feel like “technology” anymore. Before MojoTech, Joe spearheaded the development for RI.gov for more than a decade and was a part of many complex integration and automation projects. Prior to that, he spent close to half a decade at the Providence Journal. Most of Joe’s family are educators, so he can’t escape the desire to teach and help others. He is the organizer for the Rhode Island Ruby User Group and has served as an instructor for RampUp." +
              " Away from the computer, he is an adept guitar player and loves cooking for family and friends. Seriously, try his guacamole.",
          },
          {
            image: staticQuery.img,
            name: "Mark J. Mason",
            jobPosition: "CEO + Product Lead",
            biography:
              "Joe strives to improve the technology that people use in their daily lives " +
              "by making it so reliable and intuitive that it doesn’t feel like “technology” anymore. Before MojoTech, Joe spearheaded the development for RI.gov for more than a decade and was a part of many complex integration and automation projects. Prior to that, he spent close to half a decade at the Providence Journal. Most of Joe’s family are educators, so he can’t escape the desire to teach and help others. He is the organizer for the Rhode Island Ruby User Group and has served as an instructor for RampUp." +
              " Away from the computer, he is an adept guitar player and loves cooking for family and friends. Seriously, try his guacamole.",
          },
          {
            image: staticQuery.img,
            name: "Mark J. Mason",
            jobPosition: "CEO + Product Lead",
            biography:
              "Joe strives to improve the technology that people use in their daily lives " +
              "by making it so reliable and intuitive that it doesn’t feel like “technology” anymore. Before MojoTech, Joe spearheaded the development for RI.gov for more than a decade and was a part of many complex integration and automation projects. Prior to that, he spent close to half a decade at the Providence Journal. Most of Joe’s family are educators, so he can’t escape the desire to teach and help others. He is the organizer for the Rhode Island Ruby User Group and has served as an instructor for RampUp." +
              " Away from the computer, he is an adept guitar player and loves cooking for family and friends. Seriously, try his guacamole.",
          },
        ]}
      />

      <LayoutTitle>ServiceDescription</LayoutTitle>
      <ServiceDescription
        title="We’re leaders in engineering, design,
      and product management but our most valuable service is
      how we combine these disciplines to deliver for our clients."
        services={[
          {
            logo: staticQuery.img,
            serviceName: "Engineering",
            borderColor: "RGBA(84,174,52,0.4)",
            serviceItems: [
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
            ],
          },
          {
            logo: staticQuery.img,
            serviceName: "Product Design",
            borderColor: "RGBA(225,86,255,0.4)",
            serviceItems: [
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
              {
                title: "Full-stack Engineering",
                description: "Data modeling, Client & Server, Embedded Systems",
              },
            ],
          },
        ]}
      />

      <LayoutTitle>WorkCards</LayoutTitle>
      <WorkCards
        title="We build and design digital products that help transform companies."
        companies={[
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
          {
            companyName: "Midnight Express",
            technologyDescription: "UX Design, UI Design, Development",
            image: staticQuery.img,
            url: "./",
          },
        ]}
      />
      <LayoutTitle>HeroBanner</LayoutTitle>
      <HeroBanner
        title="TEST"
        companyName="name Page"
        link={{ name: "test", url: "./" }}
        services={[{ name: "Service1" }, { name: "Service2" }]}
      />
      <LayoutTitle>Careers</LayoutTitle>
      <Careers
        title="Vacantes"
        careers={[
          {
            title: "Senior UX / UI Designer",
            careers: { location: "Quito or Remote" },
            link: { url: "/" },
          },
          {
            title: "Senior UX / UI Designer",
            careers: { location: "Quito or Remote" },
            link: { url: "/" },
          },
        ]}
      />
      <LayoutTitle>CareersDescription</LayoutTitle>
      <CareersDescription
        title="Senior UX / UI Designer"
        careers={{
          benefits:
            "<p>ThoughtLab offers a salary commensurate with experience and qualifications as well as a fun, hard-working, entrepreneurial work environment. Perks include casual dress, paid vacation and insurance benefits.</p>\n<p>&nbsp;</p>\n<ul>\n<li>Health, Vision and Dental Benefits</li>\n<li>PTO &amp; Holidays</li>\n<li>Profit Sharing</li>\n<li>Weekly Get Togethers with Cash &amp; PTO Prizes</li>\n<li>Flexible Schedule</li>\n<li>Ability to work remotely or from our SLC office</li>\n</ul>\n",
          division: "Design",
          location: "Quito or Remote",
          fieldGroupName: "careers",
          mission:
            "<p>As a Senior UX UI Designer at ThoughtLab you will lead the charge of interpreting client needs and desires into easy to use, engaging user experiences. You will work directly with ThoughtLab’s clients, project managers, business analysts, creative directors, art directors and developers to ensure the integrity of each concept from fruition to completion.</p>\n",
          requirements:
            "<p>Although anyone is susceptible to the syndrome, there are patterns and certain types of people who are more prone to feeling the effects.</p>\n<p>&nbsp;</p>\n<ul>\n<li>Very strong background with at least 6+ years in designing user interfaces</li>\n<li>Experience in web analytics and multivariate tools to inform design decisions</li>\n<li>Ability to think through information architecture, process flows and interfaces</li>\n<li>Working knowledge of interactive elements in websites and mobile apps</li>\n<li>Expertise in Sketch</li>\n</ul>\n",
        }}
        description={`<p>You aren’t satisfied with the status quo. You are the rare individual who is energized by exploring the countless paths a user could take and then through thoughtful insight, narrowing down the options to only the few that will truly elevate the user experience. Not only are you strategic, but you are able to articulate your carefully thought-out strategies to others in a way that yields understanding.</p>`}
      />
      <LayoutTitle>BeforeAfterSlider</LayoutTitle>
      <BeforeAfterSlider
        beforeImage={{
          sourceUrl:
            "https://admin.aftershock.agency/wp-content/uploads/2022/01/Rectangle-2-Copy-10-3.jpg",
        }}
        afterImage={{
          sourceUrl:
            "https://admin.aftershock.agency/wp-content/uploads/2022/01/Rectangle-2-Copy-11-2.jpg",
        }}
      />
    </Layout>
  )
}

export default Layouts
