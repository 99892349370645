import styled from "styled-components"
import SectionWrapper from "../../components/section-wrapper/section-wrapper.component"
import { Container } from "@mui/material"
import CustomLink from "../../components/custom-link/custom-link.component"

export const Section = styled(SectionWrapper)`
  background-color: ${({ theme }) => theme.palette.primary.light};
`
export const Wrapper = styled(Container)``
export const LinkContainer = styled.div`
  a {
    &.active {
      &:after {
        width: 100%;
      }
    }
  }

  a {
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;

    &:after {
      transition: all 0.3s ease;
      content: "";
      position: absolute;
      height: 1px;
      background-color: ${({ theme }) => theme.palette.primary.main};

      bottom: -4px;
      width: 0;
      left: 0;
    }
  }
  &:hover:not(.logo) {
    a {
      &:after {
        width: 100%;
      }
    }
  }
`
export const Description = styled.p`
  color: ${({ theme }) => theme.palette.primary.main};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  line-height: ${({ theme }) => theme.typography.pxToRem(25)};
  font-weight: 500;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    line-height: normal;
  }

  &.link {
    font-weight: normal;
  }
`
export const Title = styled.h1`
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: ${({ theme }) => theme.palette.primary.main};
  max-width: 710px;
`
export const Services = styled.ul`
  padding: 0;
  li {
    display: inline-block;
    margin-right: 1.5em;
    font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    line-height: ${({ theme }) => theme.typography.pxToRem(21)};
    letter-spacing: ${({ theme }) => theme.typography.pxToRem(0.5)};
    color: ${({ theme }) => theme.palette.primary.main};
    text-transform: uppercase;
  }
`
export const Link = styled(CustomLink)`
  display: block;
  font-weight: 500;
`
